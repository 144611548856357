import React from 'react';

import Slider from './slider';
import { useGlobalState } from '../global/global';

import '../styles/tabs.css';

const Tabs = ({ labels, children }) => {
    const [state, dispatch] = useGlobalState();

    return (
        <div className='tabs'>
            <div className='tabs__labels'>
                {state.tabIndex > 0 ? <img onClick={() => dispatch({ tabIndex: state.tabIndex - 1 })} className='tabs_labels_image' src='/images/arrow-1.svg' alt='previous' /> : null}
                {labels.map((label, i) => {
                    if (i === state.tabIndex) {
                        return (
                            <p
                                onClick={() => dispatch({ tabIndex: i })}
                                key={i}
                                className='tabs__labels__label'
                                style={state.tabIndex === i ? { borderColor: 'white', fontWeight: 'bold', borderWidth: '3px' } : {}}
                            >
                                {label}
                            </p>
                        );
                    }
                    return (
                        <p
                            onClick={() => dispatch({ tabIndex: i })}
                            key={i}
                            className='tabs__labels__label tabs__labels__label__show'
                            style={state.tabIndex === i ? { borderColor: 'white', fontWeight: 'bold', borderWidth: '3px' } : {}}
                        >
                            {label}
                        </p>
                    );

                })}
                {state.tabIndex < labels.length - 1 ? <img onClick={() => dispatch({ tabIndex: state.tabIndex + 1 })} className='tabs_labels_image' src='/images/arrow-2.svg' alt='next' /> : null}
            </div>
            <div className='tabs__content'>
                {children.map((element, i) => {
                    if (i === state.tabIndex) {
                        return (
                            <Slider key={i}>
                                {element.props.children}
                            </Slider>
                        );

                    }
                })}
            </div>
        </div>
    );
}

export default Tabs;