import React, { useState } from 'react';

import '../styles/slider.css';

const Slider = ({ children }) => {

    const [currentPage, setCurrentPage] = useState(0);

    let elements = [];

    if (children.length > 0) {
        elements = [...children];
    } else {
        elements = [children];
    }

    return (
        <div className='slider'>
            <img style={elements.length > 3 && currentPage > 0 ? { opacity: 1 } : { opacity: 0 }} className='slider__image' src='/images/arrow-1.svg' onClick={() => setCurrentPage(currentPage - 3)}  alt='previous' />
            <div className='slider-content'>
                {elements.map((element, i) => {
                    if (i < (currentPage + 3) && i >= currentPage) {
                        return element;
                    }
                })}
            </div>
            <img style={elements.length > 3 && currentPage < elements.length - 3 ? { opacity: 1 } : { opacity: 0 }} className='slider__image' src='/images/arrow-2.svg' onClick={() => setCurrentPage(currentPage + 3)} alt='next' />
        </div>
    );
}

export default Slider;