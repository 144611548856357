import React from "react"
import { navigate } from "gatsby"

import "../styles/index.css"

import { useGlobalState } from "../global/global"
import Button from "../components/button"
import Tabs from "../components/tabs"
import AnimationOnVisible from "../global/animation"
import Layout from "../components/layout"
import Animation from "../components/animation"
import NewsModal from "../components/news-modal"

const IndexPage = props => {
  const [state, dispatch] = useGlobalState()

  const handleClick = (id, url) => {
    if (url) {
      navigate(url)
    }

    const checkExist = setInterval(function() {
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
        })

        clearInterval(checkExist)
      }
    }, 100)
  }

  const setImageSrc = imageName => {
    if (state.lightThemeState) {
      return "/images/light/" + imageName
    }

    if (!state.lightThemeState) {
      return "/images/dark/" + imageName
    }
  }

  return (
    <>
      {/* <NewsModal /> */}
      <Layout location={props.location}>
        <div className="section section--main">
          <div className="main__animation__wrapper">
            <Animation />
          </div>
          <AnimationOnVisible
            duration={300}
            delay={300}
            from={{ opacity: 0, marginTop: -20 }}
            to={{ opacity: 1, marginTop: 0 }}
          >
            <h1 className="main__title">
              We do big data so you can focus on your big plan!
            </h1>
          </AnimationOnVisible>
          <AnimationOnVisible
            duration={300}
            delay={300}
            from={{ opacity: 0, marginTop: 20 }}
            to={{ opacity: 1, marginTop: 0 }}
          >
            <p className="main__subtitle">
              Dataunitor enables your business to fuse knowledge, understanding
              and experience to take wise actions.
            </p>
          </AnimationOnVisible>
          <AnimationOnVisible
            duration={300}
            delay={300}
            from={{ opacity: 0, marginTop: 20 }}
            to={{ opacity: 1, marginTop: 0 }}
          >
            <div>
              <Button
                onClick={() => navigate("/contact")}
                label="GET IN TOUCH"
                backgroundColor="var(--main-button-background-color)"
                color="var(--main-button-color)"
              />
            </div>
          </AnimationOnVisible>
        </div>

        <div id="products" className="section section--products">
          <AnimationOnVisible
            duration={500}
            delay={0}
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
          >
            <h1 className="products__title">Products</h1>
          </AnimationOnVisible>
          <div className="products__product">
            <AnimationOnVisible
              minTopValue={100}
              delay={0}
              from={{ opacity: 0 }}
              to={{ opacity: 1 }}
            >
              <div className="products__product__content">
                <h5 className="products__product__content__name">
                  nginr
                  <sup>
                    <small>®</small>
                  </sup>
                </h5>
                <p className="products__product__content__detail">
                  Big data analytics and fusion platform enabling actionable
                  intelligence for decision support and automation.
                </p>
                <div>
                  <Button
                    onClick={() => navigate("/nginr")}
                    label="LEARN MORE"
                    backgroundColor="var(--product-button-background-color)"
                    color="var(--product-button-color)"
                  />
                </div>
              </div>
            </AnimationOnVisible>
            <AnimationOnVisible
              minTopValue={100}
              delay={0}
              from={{ opacity: 0 }}
              to={{ opacity: 1 }}
            >
              <div className="products__product__content">
                <h5 className="products__product__content__name">
                  hymots
                  <sup>
                    <small>®</small>
                  </sup>
                </h5>
                <p className="products__product__content__detail">
                  Micro Agent-Based Modeling and Simulations platform enabling
                  digital twins, serious games, experimentation, and training
                  solutions.
                </p>
                <div>
                  <Button
                    onClick={() => navigate("/hymots")}
                    label="LEARN MORE"
                    backgroundColor="var(--product-button-background-color)"
                    color="var(--product-button-color)"
                  />
                </div>
              </div>
            </AnimationOnVisible>
          </div>
        </div>
        <div id="solutions" className="section section--solutions">
          <AnimationOnVisible
            delay={0}
            duration={500}
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
          >
            <h1 className="solutions__title">Solutions</h1>
          </AnimationOnVisible>
          <AnimationOnVisible
            delay={0}
            duration={500}
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
          >
            <p className="solutions__detail">
              Dataunitor provides solutions that addresses needs in a broad
              range of industries, within the public and private sectors. Our
              products and solutions are equally fit for small and medium-sized
              enterprises as well as larger entities and governments.
            </p>
          </AnimationOnVisible>
          <Tabs
            labels={[
              "BIG DATA ANALYTICS",
              "SECURITY AND INTELLIGENCE",
              "MODELING AND SIMULATION",
            ]}
          >
            <div>
              <div
                onClick={() =>
                  handleClick("augmented-intelligence", "/big-data-analytics")
                }
                className="solutions__tab__content"
              >
                <img
                  className="solutions__tab__content__image"
                  src={setImageSrc("augmented_intelligence.svg")}
                  alt="augmented intelligence"
                />
                <h5 className="solutions__tab__content__title">
                  AUGMENTED INTELLIGENCE
                </h5>
                <p className="solutions__tab__detail">
                  Human centered hybrid human/machine solutions that enhances
                  cognitive performance.
                </p>
              </div>
              <div
                onClick={() =>
                  handleClick("artificial-intelligence", "/big-data-analytics")
                }
                className="solutions__tab__content"
              >
                <img
                  className="solutions__tab__content__image"
                  src={setImageSrc("Artificial_intelligence.svg")}
                  alt="artificial intelligence"
                />
                <h5 className="solutions__tab__content__title">
                  ARTIFICIAL INTELLIGENCE
                </h5>
                <p className="solutions__tab__detail">
                  State-of-the-art AI solutions including Machine Learning and
                  Natural Language Processing.
                </p>
              </div>
              <div
                onClick={() =>
                  handleClick(
                    "blockchain-based-analytics",
                    "/big-data-analytics"
                  )
                }
                className="solutions__tab__content"
              >
                <img
                  className="solutions__tab__content__image"
                  src={setImageSrc("blockchain_based_analytics.svg")}
                  alt="blockchain based analytics"
                />
                <h5 className="solutions__tab__content__title">
                  BLOCKCHAIN BASED ANALYTICS
                </h5>
                <p className="solutions__tab__detail">
                  Proprietary blockchain applications for traceability of assets
                  and transactions.
                </p>
              </div>
              <div
                onClick={() =>
                  handleClick("data-fabric", "/big-data-analytics")
                }
                className="solutions__tab__content"
              >
                <img
                  className="solutions__tab__content__image"
                  src={setImageSrc("information_fusion.svg")}
                  alt="data fabric"
                />
                <h5 className="solutions__tab__content__title">DATA FABRIC</h5>
                <p className="solutions__tab__detail">
                  Unified and cohesive data analytics environments across
                  multiple streams of heterogenous datasets.
                </p>
              </div>
              <div
                onClick={() =>
                  handleClick("continuous-intelligence", "/big-data-analytics")
                }
                className="solutions__tab__content"
              >
                <img
                  className="solutions__tab__content__image"
                  src={setImageSrc("continous_intelligence.svg")}
                  alt="continuous intelligence"
                />
                <h5 className="solutions__tab__content__title">
                  CONTINUOUS INTELLIGENCE
                </h5>
                <p className="solutions__tab__detail">
                  Real-time contextual data-based decision support for Internet
                  of Things and streaming applications.
                </p>
              </div>
            </div>
            <div>
              <div
                onClick={() => navigate("/ephesus-suite")}
                className="solutions__tab__content"
              >
                <img
                  className="solutions__tab__content__image"
                  src={setImageSrc("Ephesus_S.svg")}
                  alt="dataunitor ephesus-s"
                />
                <h5 className="solutions__tab__content__title">
                  nginr
                  <sup>
                    <small>®</small>
                  </sup>{" "}
                  SOLUTIONS
                </h5>
                <p className="solutions__tab__detail">
                  Customized solutions to create actionable and validated
                  knowledge from big data.
                </p>
              </div>
            </div>
            <div>
              <div
                onClick={() => navigate("/digital-twins")}
                className="solutions__tab__content"
              >
                <img
                  className="solutions__tab__content__image"
                  src={setImageSrc("Modeling_simulation.svg")}
                  alt="digital twins"
                />
                <h5 className="solutions__tab__content__title">
                  DIGITAL TWINS
                </h5>
                <p className="solutions__tab__detail">
                  Solutions enabling Industry 4.0, Smart Cities, and symbiotic
                  simulations.
                </p>
              </div>
              <div
                onClick={() => navigate("/training-simulation")}
                className="solutions__tab__content"
              >
                <img
                  className="solutions__tab__content__image"
                  src={setImageSrc("Training_simulation.svg")}
                  alt="training simulation"
                />
                <h5 className="solutions__tab__content__title">
                  TRAINING SIMULATION
                </h5>
                <p className="solutions__tab__detail">
                  State-of-the-art configurable interfaces for applications,
                  such as risk assessment and serious games.
                </p>
              </div>
              <div
                onClick={() => navigate("/hymots-solutions")}
                className="solutions__tab__content"
              >
                <img
                  className="solutions__tab__content__image"
                  src={setImageSrc("hymots.svg")}
                  alt="dataunitor hymots"
                />
                <h5 className="solutions__tab__content__title">
                  hymots
                  <sup>
                    <small>®</small>
                  </sup>{" "}
                  SOLUTIONS
                </h5>
                <p className="solutions__tab__detail">
                  Hybrid multi-domain simulations solutions for operational,
                  strategic and tactical training.
                </p>
              </div>
            </div>
          </Tabs>
        </div>
        {/* <div id="services" className="section section--services">
      <AnimationOnVisible
        minTopValue={35}
        delay={0}
        duration={500}
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
      >
        <h1 className="services__title">Services</h1>
      </AnimationOnVisible>
      <AnimationOnVisible
        minTopValue={20}
        delay={0}
        duration={500}
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
      >
        <p className="services__detail">
          For requirements that are not covered by our current portfolio of
          solutions we provide customized services such as software
          engineering, design and security.
        </p>
      </AnimationOnVisible>
      <div className="services__services">
        <AnimationOnVisible
          minTopValue={160}
          delay={0}
          duration={500}
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
        >
          <div className="services__services__group">
            <div
              onClick={() => handleClick("software-engineering", "/services")}
              className="services__services__service"
            >
              <img
                className="services__services__service__image"
                src={setImageSrc("software_engineering.svg")}
                alt="software engineering"
              />
              <h3 className="services__services__service__title">
                Software Engineering
              </h3>
              <p className="services__services__service__detail">
                Full-service software development portfolio and unique
                capabilities within the field of data science.
              </p>
            </div>
            <div
              onClick={() => handleClick("design", "/services")}
              className="services__services__service"
            >
              <img
                className="services__services__service__image"
                src={setImageSrc("UX-UI.svg")}
                alt="design"
              />
              <h3 className="services__services__service__title">Design</h3>
              <p className="services__services__service__detail">
                High quality User Interface (UI) and User Experience (UX).
              </p>
            </div>
          </div>
        </AnimationOnVisible>
        <AnimationOnVisible
          minTopValue={160}
          delay={0}
          duration={500}
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
        >
          <div className="services__services__group">
            <div
              onClick={() =>
                handleClick("modeling-and-simulation", "/services")
              }
              className="services__services__service"
            >
              <img
                className="services__services__service__image"
                src={setImageSrc("Modeling_simulation.svg")}
                alt="modeling and simulation"
              />
              <h3 className="services__services__service__title">
                Modeling and Simulation
              </h3>
              <p className="services__services__service__detail">
                Fully optimized and fully customized M&S capabilities.
              </p>
            </div>
            <div onClick={() => handleClick('digital-innovation-management','/services')} className='services__services__service'>
                              <img className='services__services__service__image' src={setImageSrc('Digital_innovation.svg')} alt='digital innovation management' />
                              <h3 className='services__services__service__title'>Digital Innovation Management</h3>
                              <p className='services__services__service__detail'>Change management, business model innovation and digital business transformation services.
                           </p>
                          </div>
          </div>
        </AnimationOnVisible>
      </div>
    </div> */}
        <div id="about-us" className="section section--about-us">
          <AnimationOnVisible
            minTopValue={35}
            delay={0}
            duration={500}
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
          >
            <div className="about-us__left__wrapper">
              <div className="about-us__left">
                <h3 className="about-us__left__title">About Us</h3>
                <p className="about-us__left__detail">
                  Dataunitor AS is a provider of products and solutions which
                  targets demanding intelligence and decision support
                  applications where security and integrity of big data
                  processing is crucial. We complement our efforts in big data
                  analytics with modeling and solutions creating hybrid and
                  connected digital and physical environments. <br />
                </p>
                <div className="about-us__left__button">
                  <Button
                    onClick={() => navigate("/about-us")}
                    label="LEARN MORE"
                    backgroundColor="var(--main-button-background-color)"
                    color="var(--main-button-color)"
                  />
                </div>
              </div>
            </div>
          </AnimationOnVisible>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
