import React, { createRef } from 'react';

class Animation extends React.Component {
    constructor(props) {
        super(props);

        this.canvasRef = createRef();
        this.c = null;
        this.canvasWidth = null;
        this.canvasHeight = null;
        this.lineColorConstant = 8;
        this.circleColor = 'rgb(255, 255, 255)';
        this.maxDx = 1;
        this.maxDy = 1;
        this.maxRadius = 3;
        this.circleNumber = null;
        this.defaultCircleNumber = 100;
        this.circles = [];
        this.lineRadius = null;
        this.defaultLineRadius = 320;
        this.mouseX = 0;
        this.mouseY = 0;
    }

    handleMouseMove(event) {
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
    }

    handleResize() {
        this.setCanvasSettings();
        this.createCircles();
    }

    Circle(stroke, color, radius, x, y, dx, dy, c) {
        this.stroke = stroke;
        this.color = color;
        this.radius = radius;
        this.x = x;
        this.y = y;
        this.dx = dx;
        this.dy = dy;
        this.c = c;

        this.randomRadius = function (radius) {
            this.radius = Math.random() * radius;
        }

        this.randomSign = function () {
            if (Math.floor(Math.random() * 10) % 2 === 0) {
                return -1;
            }

            return 1;
        }

        this.randomCoordinate = function (maxWidth, maxHeight) {
            this.x = Math.random() * (maxWidth - 2 * this.radius) + this.radius;
            this.y = Math.random() * (maxHeight - 2 * this.radius) + this.radius;
        }

        this.randomVelocity = function (dx, dy) {
            this.dx = Math.random() * dx * this.randomSign();
            this.dy = Math.random() * dy * this.randomSign();
        }

        this.draw = function () {
            this.c.beginPath();
            this.c.arc(this.x, this.y, this.radius, Math.PI * 2, false);
            this.c.closePath();
            this.c.strokeStyle = this.color;
            this.c.fillStyle = this.color;

            if (this.stroke === true) {
                this.c.stroke()
            } else {
                this.c.fill();
            }
        }

        this.update = function (width, height) {
            this.x += this.dx;
            this.y += this.dy;

            if (this.x + this.radius > width || this.x - this.radius < 0) {
                this.dx = -this.dx;
            }

            if (this.y + this.radius > height || this.y - this.radius < 0) {
                this.dy = -this.dy;
            }
        }
    }

    createCircles() {
        if (this.canvasRef.current) {
            this.circles.length = 0;
            for (var i = 0; i < this.circleNumber; i++) {
                const circle = new this.Circle(false, this.circleColor, this.radius, this.mouseX, this.mouseY, this.dx, this.dy, this.c);
                circle.randomRadius(this.maxRadius);
                circle.randomCoordinate(this.canvasWidth, this.canvasHeight);
                circle.randomVelocity(this.maxDx, this.maxDy);
                this.circles.push(circle);
            }
        }
    }

    drawLinesWithCircle(firstCircle, firstIndex) {
        let distance = undefined;
        this.circles.forEach((secondCircle, secondIndex) => {
            if (firstCircle.x < this.mouseX + this.lineRadius && firstCircle.x > this.mouseX - this.lineRadius && firstCircle.y < this.mouseY + this.lineRadius && firstCircle.y > this.mouseY - this.lineRadius
                &&
                secondCircle.x < this.mouseX + this.lineRadius && secondCircle.x > this.mouseX - this.lineRadius && secondCircle.y < this.mouseY + this.lineRadius && secondCircle.y > this.mouseY - this.lineRadius
                && secondIndex > firstIndex
            ) {
                this.c.beginPath();
                this.c.moveTo(firstCircle.x, firstCircle.y);
                this.c.lineTo(secondCircle.x, secondCircle.y);
                distance = Math.sqrt(((secondCircle.x - firstCircle.x) * (secondCircle.x - firstCircle.x)) + ((secondCircle.y - firstCircle.y) * (secondCircle.y - firstCircle.y)));
                this.c.closePath();
                this.c.strokeStyle = 'rgba(255, 255, 255, ' + (1 - (distance / (this.lineRadius * Math.sqrt(3)))) / this.lineColorConstant + ')';
                this.c.stroke();
            }
        });

    }

    animate() {
        this.c.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

        this.circles.forEach((circle, i) => {
            circle.draw();
            this.drawLinesWithCircle(circle, i);
            circle.update(this.canvasWidth, this.canvasHeight);
        });


        requestAnimationFrame(() => this.animate());
    }

    setCanvasSettings() {
        if (this.canvasRef.current) {
            const style_height = +getComputedStyle(this.canvasRef.current).getPropertyValue("height").slice(0, -2);
            const style_width = +getComputedStyle(this.canvasRef.current).getPropertyValue("width").slice(0, -2);

            this.canvasRef.current.setAttribute('height', style_height);
            this.canvasRef.current.setAttribute('width', style_width);

            this.canvasWidth = this.canvasRef.current.width;
            this.canvasHeight = this.canvasRef.current.height;

            this.circleNumber = (this.defaultCircleNumber * this.canvasHeight * this.canvasWidth) / (1920 * 1080);
            this.lineRadius = (this.defaultLineRadius * this.canvasHeight * this.canvasWidth) / (1920 * 1080);
            this.lineRadius = this.lineRadius < this.defaultLineRadius ? this.lineRadius : this.defaultLineRadius;

            this.c = this.canvasRef.current.getContext('2d');
            this.c.lineWidth = 1;
        }
    }

    componentDidMount() {
        this.setCanvasSettings();
        this.createCircles();
        this.animate();

        document.addEventListener('mousemove', this.handleMouseMove.bind(this));
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleMouseMove.bind(this));
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    render() {
        return (
            <canvas ref={this.canvasRef} style={{ display: 'block', width: '100%', height: '100%' }} id="canvas"></canvas>
        );
    }
}

export default Animation;